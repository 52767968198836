export const starsTuck = {
"type": "FeatureCollection",
"name": "tuck_stars",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "BASIN_NAME": "Tuck", "SUBBASIN_ID": "WOLF_TUC", "DisplayName": "Wolf Creek", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.2279, "LON": -83.0016}, "geometry": { "type": "Point", "coordinates": [ -83.1232, 35.2379 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Tuck", "SUBBASIN_ID": "TANA_TUC", "DisplayName": "Tanasee", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.2133, "LON": -83.0016 }, "geometry": { "type": "Point", "coordinates": [ -83.0016, 35.2133 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Tuck", "SUBBASIN_ID": "BEAR_TUC", "DisplayName": "Bear Creek ", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.24063, "LON": -83.07151 }, "geometry": { "type": "Point", "coordinates": [ -83.07151, 35.24063 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Tuck", "SUBBASIN_ID": "CEDA_TUC", "DisplayName": "Cedar Cliff ", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.2536, "LON": -83.0987}, "geometry": { "type": "Point", "coordinates": [ -83.0987, 35.2536 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Tuck", "SUBBASIN_ID": "GLEN_TUC", "DisplayName": "Glenville", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.1953, "LON": -83.1565 }, "geometry": { "type": "Point", "coordinates": [ -83.1565, 35.1953 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Tuck", "SUBBASIN_ID": "THOR_TUC", "DisplayName": "Thorpe ", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.2379, "LON": -83.1232 }, "geometry": { "type": "Point", "coordinates": [ -83.1232, 35.2379 ] } }
]
}