import { Button } from "@mui/material";
import Plot from "react-plotly.js";
import {
  formatPlotData,
  formatNameForTitle,
  generateLineStyle,
  generateMarkerStyle,
  chooseEvaluateYAxis,
  chooseType,
  chooseOffset,
  chooseWidth,
  choosePlotTraceName,
  chooseFill,
  chooseFillColor,
  // findHindcastStart,
  // findForecastEnd,
  setYRange,
  addDays,
  subtractDays
} from "../Plots/PlotCustomHelpers";
import { createShapesMainEvaluate } from "../Plots/ShapesThumbnailEvaluate";
import "../Plots/MainPlot.css";


export const ThumbnailPlotEvaluate= ({ basin, basinClick, goBackDays }) => {
  // const clipBasinName = (basinFullName) => {
  //   return basinFullName.split("_")[0];
  // };
 //console.log("basin: ",basin);
  const processedData = formatPlotData(basin.ts);
  // console.log("***processedData");
  // console.log(processedData);


  const notRegularTS = (name) => {
    if (
      name === "QPE_to_MAPCumulativePastPrecip" ||
      name === "PreprocessHRRRCumulativeShortPrecip" ||
      name === "PreprocessGFSCumulativeMedPrecip" ||
      name.includes("Unadj") ||
      name === "ThresholdsCustomBooleanThreshold"
    ) {
      return true;
    } else {
      return false;
    }
  };


  //onsole.log("ts:", processedData);
  const plotableTS = processedData.filter((ts) => !notRegularTS(ts.name));

  const generatePlotDataFormat = plotableTS
    .map((ts) => {
      let tsObject;
      if (chooseType(ts.name) === "bar") {
        tsObject = {
          x: ts.x,
          y: ts.y,
          yaxis: chooseEvaluateYAxis(ts.name),
          type: "bar",
          name: choosePlotTraceName(ts.name),
          marker: generateMarkerStyle(ts.name),
          width: chooseWidth(ts.name),
          offset: chooseOffset(ts.name),
          hovertemplate: 
            '%{y:.2f}' +
            '<br>%{x}<br>' ,
        };
      } else {
        tsObject = {
          x: ts.x,
          y: ts.y,
          yaxis: chooseEvaluateYAxis(ts.name),
          mode: "lines",
          name: choosePlotTraceName(ts.name),
          line: generateLineStyle(ts.name),
          // text: ts.name,

          showlegend:
            // ts.name === "ThresholdsStreamflowThreshold" ||
            ts.name === "Preprocess_NWMMedRangeMax"
              ? false
              : true,
          fill: chooseFill(ts.name),
          fillcolor: chooseFillColor(ts.name),
          hovertemplate: 
            '%{y:.2f}' +
            '<br>%{x}<br>' ,
        };
      }

      return tsObject;
    })
    .sort((a, b) => (a.name > b.name && 1) || -1);

  // const shapesArray = createShapesThumbnailCustom(plotableTS, basin.ts);
  const shapesArray = createShapesMainEvaluate(basin);

  
  return (
    <>
      <Button
        className="thumbnail-btn"
        onClick={() => basinClick(basin.name)}
      >
        <Plot
          data={generatePlotDataFormat}
          config={{ displayModeBar: false }}
          layout={{
            autosize: true,
            title: {
              text: formatNameForTitle(basin.name),
              // text: basin.name
              //   .split(/(?=[A-Z])/)
              //   .join(" ")
              //   .split("_")
              //   .join(" "),
              font: {
                color: "black",
                size: 14,
              },
            },
            showlegend: false,
            hovermode: "closest",
            /*
            yaxis1: {
              autoscale: true,
              title: {
                text: "Pool El.",
                font: {
                  color: "black",
                  size: 10,
                },
              },
              gridcolor: "lightgrey",
              hoverformat: ".0f",
              mirror: "all",
              showgrid: true,
              showline: true,
              tickformat: ",",
              zeroline: true,
              domain: [0, 0.2],
              rangemode: "tozero",
              tickfont: {
                size: 10,
              },
            },*/


            yaxis1: {
              autoscale: true,
              title: {
                text: "Flow",
                font: {
                  color: "black",
                  size: 10,
                },
              },
              gridcolor: "lightgrey",
              hoverformat: ".0f",
              mirror: "all",
              showgrid: true,
              showline: true,
              tickformat: ",",
              zeroline: true,
              domain: [0.0, 0.42],
              rangemode: "tozero",
              tickfont: {
                size: 10,
              },
            },

            yaxis2: {
              autoscale: true,
              title: {
                text: "Cuml",
                font: {
                  color: "black",
                  size: 10,
                },
              },
              gridcolor: "lightgrey",
              hoverformat: ".2f",
              mirror: "all",
              showgrid: true,
              showline: true,
              tickformat: ".2f",
              zeroline: true,
              domain: [0.44, 0.64],
              rangemode: "tozero",
              range: setYRange(generatePlotDataFormat, "y2"),
              tickfont: {
                size: 10,
              },
            },
            yaxis3: {
              autoscale: true,
              title: {
                text: "Inst",
                font: {
                  color: "black",
                  size: 10,
                },
              },
              gridcolor: "lightgrey",
              hoverformat: ".2f",
              mirror: "all",
              showgrid: true,
              showline: true,
              tickformat: ".2f",
              zeroline: true,
              tickfont: {
                size: 10,
              },
              domain: [0.66, 0.86],
              rangemode: "tozero",
              range: setYRange(generatePlotDataFormat, "y3"),
            },
            xaxis: {
              title: {
                text: "Datetime",
                font: {
                  size: 10,
                },
              },
              gridcolor: "lightgrey",
              mirror: "all",
              showgrid: true,
              showline: true,
              zeroline: true,
              range: [subtractDays(Date.now(), goBackDays), addDays(Date.now(), 10)],
              tickfont: {
                size: 10,
              },
            },
            margin: { r: 5, l: 50, t: 30, b: 40 },
            shapes: shapesArray,
            
          }}
          useResizeHandler={true}
          className="thumbnail-plot"
        />
      </Button>
    </>
  );
};
