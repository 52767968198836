export const squaresCatawba = {
    "type": "FeatureCollection",
    "name": "catawba_squares",
    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
    "features": [
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "LOWL_CAT", "DisplayName": "Lower Little R	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.222	 , "LAT":	35.996	}, "geometry": { "type": "Point", "coordinates": [ 	-81.222	,	35.996	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "PLEA_CAT", "DisplayName": "Pleasant Gardens	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-82.178	 , "LAT":	35.638	}, "geometry": { "type": "Point", "coordinates": [ 	-82.178	,	35.638	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "LINV_CAT", "DisplayName": "Linville River	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.9	 , "LAT":	35.979	}, "geometry": { "type": "Point", "coordinates": [ 	-81.9	,	35.979	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "JOHN_CAT", "DisplayName": "Johns River	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.726	 , "LAT":	36.003	}, "geometry": { "type": "Point", "coordinates": [ 	-81.726	,	36.003	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "DUTC_CAT", "DisplayName": "Dutchmans Cr.	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.087	 , "LAT":	35.434	}, "geometry": { "type": "Point", "coordinates": [ 	-81.087	,	35.434	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "ROCK_CAT", "DisplayName": "Rocky Creek	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.072	 , "LAT":	34.647	}, "geometry": { "type": "Point", "coordinates": [ 	-81.072	,	34.647	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "SUGA_CAT", "DisplayName": "Sugar Creek	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-80.936	 , "LAT":	35.069	}, "geometry": { "type": "Point", "coordinates": [ 	-80.936	,	35.069	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "MORG_CAT", "DisplayName": "Abv Rhodiss 	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.825	 , "LAT":	35.682	}, "geometry": { "type": "Point", "coordinates": [ 	-81.825	,	35.682	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "UPPL_CAT", "DisplayName": "Upper Little R	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.411	 , "LAT":	35.895	}, "geometry": { "type": "Point", "coordinates": [ 	-81.411	,	35.895	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "MIDL_CAT", "DisplayName": "Middle Little R	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.329	 , "LAT":	35.924	}, "geometry": { "type": "Point", "coordinates": [ 	-81.329	,	35.924	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "FCRK_CAT", "DisplayName": "Fishing Creek	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.072	 , "LAT":	34.83	}, "geometry": { "type": "Point", "coordinates": [ 	-81.072	,	34.83	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "BELW_CAT", "DisplayName": "Bowater 	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-80.807	 , "LAT":	34.962	}, "geometry": { "type": "Point", "coordinates": [ 	-80.807	,	34.962	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "MCDO_CAT", "DisplayName": "McDowell Cr	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-80.876	 , "LAT":	35.422	}, "geometry": { "type": "Point", "coordinates": [ 	-80.876	,	35.422	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "SFOR_CAT", "DisplayName": "South Fork	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.288	 , "LAT":	35.527	}, "geometry": { "type": "Point", "coordinates": [ 	-81.288	,	35.527	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "WARR_CAT", "DisplayName": "Warrior Fork	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.813	 , "LAT":	35.873	}, "geometry": { "type": "Point", "coordinates": [ 	-81.813	,	35.873	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "HENR_CAT", "DisplayName": "Henry Fork	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.579	 , "LAT":	35.665	}, "geometry": { "type": "Point", "coordinates": [ 	-81.579	,	35.665	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "JACO_CAT", "DisplayName": "Jacob Fork	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.624	 , "LAT":	35.596	}, "geometry": { "type": "Point", "coordinates": [ 	-81.624	,	35.596	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "KILL_CAT", "DisplayName": "Killian Creek	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.067	 , "LAT":	35.5	}, "geometry": { "type": "Point", "coordinates": [ 	-81.067	,	35.5	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "INDC_CAT", "DisplayName": "Indian Creek	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.388	 , "LAT":	35.465	}, "geometry": { "type": "Point", "coordinates": [ 	-81.388	,	35.465	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "LATG_CAT", "DisplayName": "LATg Creek nr Rhyne	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-80.892	 , "LAT":	35.322	}, "geometry": { "type": "Point", "coordinates": [ 	-80.892	,	35.322	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "LATC_CAT", "DisplayName": "LATg Creek near Bessemer City	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.3	 , "LAT":	35.313	}, "geometry": { "type": "Point", "coordinates": [ 	-81.3	,	35.313	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "SUGP_CAT", "DisplayName": "Sugar Creek near Pineville	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-80.891	 , "LAT":	35.211	}, "geometry": { "type": "Point", "coordinates": [ 	-80.891	,	35.211	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "LITS_CAT", "DisplayName": "Little Sugar Creek	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-80.817	 , "LAT":	35.203	}, "geometry": { "type": "Point", "coordinates": [ 	-80.817	,	35.203	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "MCAL_CAT", "DisplayName": "McAlpine Creek	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-80.767	 , "LAT":	35.131	}, "geometry": { "type": "Point", "coordinates": [ 	-80.767	,	35.131	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "ALLI_CAT", "DisplayName": "Allison Creek	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.218	 , "LAT":	35.078	}, "geometry": { "type": "Point", "coordinates": [ 	-81.218	,	35.078	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "FISY_CAT", "DisplayName": "Fishing Creek below York	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.208	 , "LAT":	35.012	}, "geometry": { "type": "Point", "coordinates": [ 	-81.208	,	35.012	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "WILC_CAT", "DisplayName": "Wildcat Creek	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.084	 , "LAT":	34.948	}, "geometry": { "type": "Point", "coordinates": [ 	-81.084	,	34.948	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "CRRH_CAT", "DisplayName": "Catawba River near Rock Hill	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-81.028	 , "LAT":	34.992	}, "geometry": { "type": "Point", "coordinates": [ 	-81.028	,	34.992	] } } ,
        { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "CAMD_CAT", "DisplayName": "Wateree River near Camden	", "TabGroup": "Evaluate",  "Point_Type": "Eval", "LON":	-80.743	 , "LAT":	34.306	}, "geometry": { "type": "Point", "coordinates": [ 	-80.743	,	34.306	] } } ,
        
]

}