import { useState, useEffect, useRef } from "react";
import { Paper, Typography, IconButton } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { ProjectLayout } from "../Layouts/ProjectLayout";
import { Dashboard } from "../Dashboard/Dashboard";
import { CustomDashboard } from "../CustomDashboard/CustomDashboard";
import { EvaluateForecasts } from "../CustomDashboard/EvaluateForecasts";

export const ProjectsSummary = () => {
  
  const [watershed, setWatershed] = useState("");
  const [evaluate, setEvaluate] = useState(null);
  const [fcastGroup, setFcastGroup] = useState("");
  const [user, setUser] = useState(null);
  const prevWatershedRef = useRef("");
  const prevEvaluateRef = useRef("");

  useEffect(() => {
    //Add to Local Storage

    const userObj = JSON.parse(window.localStorage.getItem("afs_user"));
    if (userObj) {
      setUser(userObj);
    }
  }, []);

  useEffect(() => {
    prevWatershedRef.current = watershed;
  }, [watershed]);

  const handleBackToSummaryPg = () => {
    setWatershed("");
    setEvaluate("");
  };

  useEffect(() => {
    prevEvaluateRef.current = evaluate;
  }, [evaluate]);

  const goToEvaluateForecasts = () => {
    setEvaluate("EVALUATE");
  };
  const goToInflowForecasts = () => {
    setEvaluate(null);
  };

  return (
    <ProjectLayout>
      {!watershed && (
        <div className="side-by-side-papers">
          <Paper
            sx={{
              p: 2,
              marginTop: "5%",
              marginRight: "auto",
              marginLeft: "auto",
              maxWidth: 400,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              flexGrow: 7
            }}
          >
          <Typography variant="h5">Custom Forecasts</Typography>
          {user?.groups.map((group) => {
            const groupName =
              group.name.charAt(0).toUpperCase() + group.name.slice(1);
            let groupNameDisplay = groupName.replace("_", " ");
            groupNameDisplay = groupNameDisplay.replace("tuck", "Tuckasegee");
            groupNameDisplay = groupNameDisplay.replace("nant", "Nantahala");
            groupNameDisplay = groupNameDisplay.replace("yadkin", "Yadkin");
            groupNameDisplay = groupNameDisplay.replace("catawba", "Catawba");
            groupNameDisplay = groupNameDisplay.replace("pigeon", "Pigeon");

            groupNameDisplay = groupNameDisplay.replace("keowee", "Keowee"); 

            if(groupName.startsWith("Custom_")) 
              return (
                <IconButton
                  key={group.id}
                  onClick={() => {
                    prevWatershedRef.current = groupName;
                    setFcastGroup("custom");
                    setWatershed(groupName);
                    setEvaluate(null);
                  }}
                >
                  <DashboardIcon /> {groupNameDisplay}
                </IconButton>
              )
              return(null);
          })}
        </Paper>
       {/* <Paper
          sx={{
            p: 2,
            marginTop: "5%",
            marginLeft: "3%",
            marginRight: "auto",
            maxWidth: 400,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            flexGrow: 7
          }}
        >
          
           <Typography variant="h5">NWM-Based Forecasts</Typography>
          {user?.groups.map((group) => {
            const groupName =
              group.name.charAt(0).toUpperCase() + group.name.slice(1);
              
            if(['Yadkin', 'Catawba'].includes(groupName))
              return (
                <IconButton
                  key={group.id}
                  onClick={() => {
                    prevWatershedRef.current = groupName;
                    setFcastGroup("nwm");
                    setWatershed(groupName);
                    setEvaluate(null);
                  }}
                >
                  <DashboardIcon /> {groupName}
                </IconButton>
              )
              return(null);
          })}
        </Paper> */}
        </div>
      )}
      
      {watershed && fcastGroup==="nwm" && !evaluate && (
        <Dashboard
          watershed={watershed}
          handleBackToSummaryPg={handleBackToSummaryPg}
        />
      )}
       {watershed && fcastGroup==="custom" && !evaluate && (
        <CustomDashboard
          watershed={watershed}
          handleBackToSummaryPg={handleBackToSummaryPg}
          goToEvaluateForecasts={goToEvaluateForecasts}
          evaluate={evaluate}
          goToInflowForecasts={goToInflowForecasts}


        />
      )}
      {watershed && fcastGroup==="custom" && evaluate==="EVALUATE" && (
        <EvaluateForecasts
          evaluate={evaluate}
          watershed={watershed}
          handleBackToSummaryPg={handleBackToSummaryPg}
          goToInflowForecasts={goToInflowForecasts}
          goToEvaluateForecasts={goToEvaluateForecasts}

        />
      )}
    </ProjectLayout>
  );
};