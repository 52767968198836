import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Paper,
  styled,
  CircularProgress,
  Typography,
} from "@mui/material";
import { EvaluateMap } from "../Map/EvaluateMap";
import { MainEvaluatePlot } from "../Plots/MainEvaluatePlot";
import { WFPToolbarCustom } from "../WFPToolbar/WFPToolbarCustom";
import Request from "../../Utils/Request";
import {
  extractThresholds,
  processTSForThumbnails,
  extractSquareTimeseries
} from "../../Utils/evaluateHelpers";
import { REACT_APP_FEWS_PREFIX } from "../../Utils/constants";
import { ThumbnailContainerEvaluate } from "../Thumbnails/ThumbnailContainerEvaluate";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary,
  height: "75vh",
}));

export const EvaluateForecasts = ({ 
    watershed, 
    handleBackToSummaryPg, 
    evaluate, 
    goToEvaluateForecasts,
    goToInflowForecasts
   }) => {
    const [timeOfRefresh, setTimeOfRefresh] = useState(new Date());
    const [thresholds, setThresholds] = useState(["initial state"]);
    const [selectedBasin, setSelectedBasin] = useState("");
    const [error, setError] = useState("");
    // const [allWatershedTimeseries, setAllWatershedTimeseries] = useState([]);
    const [squareTimeseries, setSquareTimeseries] = useState([]);
  
    const watershedId = watershed.replace('Custom_','').substring(0,3).toUpperCase();

    const [shortFlowForecastDateTime, setShortFlowForecastDateTime] = useState({
      date: "",
      time: "",
    });
    const [medFlowForecastDateTime, setMedFlowForecastDateTime] = useState({
      date: "",
      time: "",
    });
  
    const handleScrollUp = () => {
      window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    };
    // console.log("EvaluateForecasts evaluate:", evaluate);
    const basinClick = (basinId) => {
      setSelectedBasin(basinId);
      // console.log("SELECTED:", basinId);
      handleScrollUp();
    };
    useEffect(() => {
      // fetch ALL timeseries for ALL basins in the watershed
      const today = Date.now();
      // const startTime = new Date(today - 86400000 * 2).toISOString();
      const startTime = new Date(today - 86400000 * 10).toISOString();
      const endTime = new Date(today + 86400000 * 10.25).toISOString();
  
      setError("");
      const url1 =
        REACT_APP_FEWS_PREFIX +
        `timeseries?filterId=${watershed}Filters&omitMissing=true&startTime=${startTime}&endTime=${endTime}&useDisplayUnits=true&showThresholds=true&showProducts=false&onlyHeaders=false&showEnsembleMemberIds=false&documentVersion=1.26&documentFormat=PI_JSON&forecastCount=1`;
        
      /*const url2 =
        REACT_APP_FEWS_PREFIX +
        `timeseries?filterId=Inflow_Fcst&startTime=${startTime}&endTime=${endTime}&useDisplayUnits=true&showThresholds=true&showProducts=false&omitMissing=true&onlyHeaders=false&showEnsembleMemberIds=false&documentVersion=1.26&documentFormat=PI_JSON&forecastCount=1`;
      */
      const fetchAllTimeseries = async () => {
        const res1 = await new Request().get(url1);
       // const res2 = await new Request().get(url2);

        if (res1.response.status !== 200  ) {  //|| res2.response.status !== 200
          setError(
            "Sorry, there was an error: " +
              res1.response.status +
              res1.response.statusText 
              //res2.response.status +
              //res2.response.statusText
          );
        } else {
          // function to extract short range flow forecast time
          // setShortFlowForecastDateTime(
          //   extractShortFlowForecastDateTime(res.data.timeSeries)
          // );
          // // function to extract lastmed flow forecast time
          setSquareTimeseries(
            extractSquareTimeseries(res1.data.timeSeries)
          );
          // setAllWatershedTimeseries(res.data.timeSeries);
          setSquareTimeseries(
            extractSquareTimeseries(res1.data.timeSeries));
            //extractSquareTimeseries([...res1.data.timeSeries,...res2.data.timeSeries]));

        }
      };
      fetchAllTimeseries();
      // re-fetch data every 5 minutes to get most recent data
      const timer = setInterval(fetchAllTimeseries, 300000);
      // clean up interval so it doesn't create a memory leak on component unmount
      return () => {
        clearInterval(timer);
      };
    }, [watershed]);
  
    useEffect(() => {
      // Get threshold exceedences
      // start time is -48 hours from right now
      const today = Date.now();
      const startTime = new Date(today - 86400000 * 2).toISOString();
  
      // end time is + 10 days from right now
      const endTime = new Date(today + 86400000 * 10).toISOString();
      setError("");
  
      const url =
        REACT_APP_FEWS_PREFIX + 
        `timeseries?filterId=Filter_${watershed}_Thresholds&startTime=${startTime}&endTime=${endTime}&qualifierIds=BooleanThreshold&useDisplayUnits=true&showThresholds=true&showProducts=false&omitMissing=true&onlyHeaders=false&showEnsembleMemberIds=false&documentVersion=1.26&documentFormat=PI_JSON&forecastCount=1`;
        
      // note: not all basins have thresholds! but that's ok as long as the default
      // 'highlight' basin doesn't have to be one of the basins without thresholds
      const fetchThresholds = async () => {
        const res = await new Request().get(url);
  
        if (res?.data.timeSeries) {
          const processedThresholds = extractThresholds(res.data.timeSeries);
  
          setThresholds(processedThresholds);
          
          //Specify default big plot
          if (watershed ==="Custom_tuck"){
            setSelectedBasin("GAGE_TUC"); 
          }
          else if (watershed ==="Custom_catawba"){
            setSelectedBasin("PLEA_CAT");
          }
          else if (watershed ==="Custom_yadkin"){
            setSelectedBasin("PATT_YAD");
          }
          else if (watershed ==="Custom_nant"){
            setSelectedBasin("RAIN_NAN");
          }
          else if (watershed ==="Custom_pigeon"){
            setSelectedBasin("WATE_PIG");
          }
          else if (watershed ==="Custom_keowee"){
            setSelectedBasin("EAST_KEO");
          }
          //setSelectedBasin(processedThresholds[0].name);
      
          setTimeOfRefresh(new Date());
        } else {
          setError("ERROR: " + res.response.status);
        }
      };
      // re-fetch data every 5 minutes to get most recent data
      fetchThresholds();
      const timer = setInterval(fetchThresholds, 300000);
      // clean up interval so it doesn't create a memory leak on component unmount
      return () => {
        clearInterval(timer);
      };
    }, [watershed]);

 /*    console.log(selectedBasin);
     console.log("*** setSquareTimeseries");
     console.log(squareTimeseries); */
  

return (
  <>
  {shortFlowForecastDateTime && medFlowForecastDateTime ? (
    <WFPToolbarCustom
      handleBackToSummaryPg={handleBackToSummaryPg}
      timeOfRefresh={timeOfRefresh}
      goToEvaluateForecasts={goToEvaluateForecasts}
      goToInflowForecasts={goToInflowForecasts}
      evaluate={evaluate}
    />
  ) : (
    <>
      <CircularProgress />
      <Typography>Loading plot data...</Typography>
    </>
  )}
  <Box
    sx={{
       // margin: "2%",
       margin: "1.5%",
    }}
  >
   {/* <Grid container columnSpacing={2}> */}
          {/* <Grid item xs={5}> */}
    <Grid container columnSpacing={1}>
      <Grid item xs={4}>
        <Item>
          <EvaluateMap
            basinClick={basinClick}
            watershed={watershed}
            thresholds={thresholds}
          />
        </Item>
      </Grid>
      {/* <Grid item xs={7}> */}
      <Grid item xs={8}>
  <div>
  </div>
        <Item>
          {error ? (
            <Typography color="error">
              {error} Your session may have expired. Please logout and
              re-enter your credentials.
            </Typography>
          ) : squareTimeseries.length > 0 &&
              selectedBasin ? (

              <MainEvaluatePlot
                selectedBasin={selectedBasin}
                allTs={squareTimeseries}
                thresholds={thresholds}
              />
            ) : (
            <>
              <CircularProgress />
              <Typography>Loading plot data...</Typography>
            </>
          )}
        </Item>
      </Grid>
    </Grid>
    <Grid container columnSpacing={2}>
        <ThumbnailContainerEvaluate
          allTs={processTSForThumbnails(squareTimeseries)}
          watershedId= {watershedId}
          basinClick={basinClick}
          goBackDays={10}
        />
    </Grid>
  </Box>
</>
);
};
