import { useState } from "react";


export const getT0 = (array) => {
  let t0 = array;
  return t0;
};


export const extractThresholds = (array) => {
  const thresholdsArray = array.map((basinItem) => {
    return {
      // name: basinItem.header.stationName.split("_")[0],
      name: basinItem.header.stationName,
      threshold: basinItem.events
        ? basinItem.events
            .map((timestep) => parseInt(timestep.value))
            .reduce((prev, next) => prev + next)
        : 0,
    };
  });

  return thresholdsArray;
};

export const processTSForThumbnails = (fullArray) => {
  // each basin object has its name, and an array of timeseries
  // {name: James,  ts: [{ts1}, {ts2}, {ts3}]}

  // create set of basin names
  const setOfBasins = new Set();
  fullArray.forEach((ts) => {
    //console.log("processTSforThumb: ",ts.header.locationId, ts.header.moduleInstanceId);
    setOfBasins.add(ts.header.locationId);
  });

  // create array of objects, each obj has a basin
  const processedTS4Thumbnails = [];
  setOfBasins.forEach((basin) => {
    processedTS4Thumbnails.push({ name: basin, ts: [] });
  });

  // then loop through fullArray and add each ts to the correct basin object
  fullArray.forEach((ts) => {
    processedTS4Thumbnails.forEach((basinObj) => {
      if (basinObj.name === ts.header.locationId) {
        basinObj.ts.push(ts);
      }
    });
  });
  //console.log("procssedstar: ", processedTS4Thumbnails);
  return processedTS4Thumbnails;
};

export const extractStarTimeseries = (allTs) => {
  // REORDER THUMBNAILS
  var sortOrder = [
      'KERR_YAD','HIGH_YAD','TUCK_YAD','NARR_YAD','FALL_YAD','TILL_YAD','BLEW_YAD',
      'JAME_CAT','RHOD_CAT','HICK_CAT','LOOK_CAT','NORM_CAT','MOUN_CAT','WYLI_CAT','FISH_CAT','GREA_CAT','CEDA_CAT','WATR_CAT',
      'LAKE_NAN','QUEE_NAN',
      'GLEN_TUC','TANA_TUC','WOLF_TUC','BEAR_TUC','CEDA_TUC','TUCK_TUC','DILL_TUC',
      'BADC_KEO','JOCA_KEO','KEOW_KEO',
      'WALT_PIG'

  ]; 
  allTs.sort((a, b) => {
    let fa = a.header.locationId;
    let fb = b.header.locationId;
    return sortOrder.indexOf(fa) - sortOrder.indexOf(fb);
  });

  const starTimeseries = allTs.filter((ts) => {
    return (
      (ts.header.locationId === "BLEW_YAD") ||
      (ts.header.locationId === "FALL_YAD") ||
      (ts.header.locationId === "HIGH_YAD") ||
      (ts.header.locationId === "TILL_YAD") ||
      (ts.header.locationId === "NARR_YAD") ||
      (ts.header.locationId === "TUCK_YAD") ||
      (ts.header.locationId === "KERR_YAD") ||

      (ts.header.locationId === "CEDA_CAT") ||
      (ts.header.locationId === "FISH_CAT") ||
      (ts.header.locationId === "GREA_CAT") ||
      (ts.header.locationId === "HICK_CAT") ||
      (ts.header.locationId === "JAME_CAT") ||
      (ts.header.locationId === "NORM_CAT") ||
      (ts.header.locationId === "RHOD_CAT") ||
      (ts.header.locationId === "WATR_CAT") ||
      (ts.header.locationId === "WYLI_CAT") ||
      (ts.header.locationId === "LOOK_CAT") ||
      (ts.header.locationId === "MOUN_CAT") ||

      (ts.header.locationId === "WOLF_TUC") ||
      (ts.header.locationId === "TANA_TUC") ||
      (ts.header.locationId === "BEAR_TUC") ||
      (ts.header.locationId === "CEDA_TUC") ||
      (ts.header.locationId === "GLEN_TUC") ||
      (ts.header.locationId === "TUCK_TUC") ||

      // (ts.header.locationId === "RAIN_NAN") ||
      (ts.header.locationId === "LAKE_NAN") ||
      (ts.header.locationId === "QUEE_NAN") ||
      (ts.header.locationId === "BADC_KEO") ||
      (ts.header.locationId === "JOCA_KEO") ||
      (ts.header.locationId === "KEOW_KEO") ||
      (ts.header.locationId === "WALT_PIG") 

    );
  });
  //console.log("Star: ", starTimeseries);
  // return processTSForThumbnails(allTs);
  return starTimeseries;
};

export const grabSelectedBasinTs = (allTs, selectedBasin) => {
  // find the object which matches selectedBasin
  // return that object's ts only!

  const basinOfInterest = allTs.find((obj) => {
    return obj.name.match(selectedBasin);
  });

  return basinOfInterest.ts;
};
